'use strict'
angular.module('cb.core.sw-service')
	.factory('SwService', ["$q", "NotificationService", function ($q,NotificationService) {
		var SwService = {
			/**
			 * Sends a message with an action to the Service Worker to carryout.
			 * this will retuan a Promise with data if the action returns any, or will invok an error alert if unsuccsesful.
			 *@example
			 *  ```js
			 * const datapromise = sendAction({action:'getUserData', probs:id});
			 * datapromise.then(data=>{console.log(data)});
			 * ```
 			 * @param {{action:string, probs:string,id}} action - Action with any probs to invoke
			 * @returns {angular.IPromise<Object>} An angular Promise
			 */
			sendAction: function (action) {
				// Create a Message Channel
				let msg_chan = new MessageChannel();

				let deferred = $q.defer();
				// Handler for recieving message reply from service worker
				msg_chan.port1.onmessage = function (event) {
					if(event.data == undefined) {
						// NotificationService.alert('Service Worker Error', 'The service worker had a error. ', 'error')
						deferred.resolve(true)
					}else if (event.data.error) {
						//console.error(event);

						if (event.data){
							NotificationService.alert('Service Worker Error', 'The service worker had a error. ' +event.data.msg, 'error')
						} else{NotificationService.alert('Service Worker Error', 'The service worker had a error', 'error')}
						deferred.reject(event.data)
					}
					 else {
						//  console.log(event.data)
						// console.log(event);
						deferred.resolve(event.data)
					}
				};
				// Send message to service worker along with port for reply
				if (navigator != null || navigator != undefined)
					if (navigator.serviceWorker != null || navigator.serviceWorker != undefined)
						if (navigator.serviceWorker.controller != null || navigator.serviceWorker.controller != undefined)
							navigator.serviceWorker.controller.postMessage(action, [msg_chan.port2]);

				return deferred.promise;



			}
		}
		return SwService;

	}])
